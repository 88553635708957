/**
 * Developed by
 * Arthur Vasconcelos <vasconcelos.arthur@gmail.com>
 * 26/01/2017
 */

'use strict';

import i18n from './components/i18n';

// INIT
i18n.init();

document.querySelectorAll('.tryTranslate').forEach(function(el) {
    el.addEventListener('click', (e) => {
        i18n.translate();
    });
});

document.getElementById('languageSelector').addEventListener('change', (e) => {
    i18n.change(e.target.value);
    reloadTranslatedCountries();
    updateData();
    loadSearchBox();
});