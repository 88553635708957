/**
 * Developed by
 * Arthur Vasconcelos <vasconcelos.arthur@gmail.com>
 * 26/01/2017
 */

'use strict';

import i18next from 'i18next';
import XHR from 'i18next-xhr-backend';
import {defaults as _defaults, clone as _clone} from 'underscore';
import fetcher from './query-string-fetcher';

const i18n = {
  keys: document.querySelectorAll('translate'),
  langSelector: document.getElementById('languageSelector'),
  init(options = {}) {
    const defaults = {
      debug: true,
      lang: fetcher().l || 'en',
      fallbackLang: 'en',
      preload: ['en', 'pt', 'es'],
      loadPath: '/assets/locales/{{lng}}/{{ns}}.json'
    };

    (options.constructor === {}.constructor) ? _defaults(options, defaults) : options = defaults;

    i18next
      .use(XHR)
      .init({
        debug: options.debug,
        lng: options.lang,
        fallbackLng: options.fallbackLang,
        preload: options.preload,
        ns: ['translations'],
        defaultNS: ['translations'],
        keySeparator: '.',
        nsSeparator: false,
        backend: {
          loadPath: options.loadPath,
          allowMultiLoading: false,
          crossDomain: false,
          withCredentials: false
        }
      }, (err, t) => {
        if(err)
          console.error(err);

        document.documentElement.lang = i18next.language;


        this.langSelector.innerHTML = '';
        for(let l of options.preload) {
          let selected = (l == i18next.language) ? 'selected' : '';
          this.langSelector.innerHTML += '<option value="' + l + '" ' + selected + '>' + i18next.t('languages.' + l) + '</option>';
        }
        // this.langSelector.style = 'display: none'; // REMOVE THIS LINE TO SHOW LANG SELECTOR

        setTimeout(function() {
          let nkeys = document.querySelectorAll('translate');
          for(let key of nkeys) {
            key.innerHTML = i18next.t(key.dataset.key);
          }
        }, 2000);
      });
  },
  t(key) {
    return i18next.t(key);
  },
  change(lang) {
    i18next.changeLanguage(lang, (err, t) => {
      if(err)
        console.error(err);

      let nkeys = document.querySelectorAll('translate');
      for(let key of nkeys) {
        key.innerHTML = i18next.t(key.dataset.key);
      }
    });
  },
  translate() {
    let nkeys = document.querySelectorAll('translate');
    for(let key of nkeys) {
      key.innerHTML = i18next.t(key.dataset.key);
    }
  }
};

// Export public methods.
window.i18n = {
  t: i18n.t
};

// Export whole object to import.
export default i18n;
